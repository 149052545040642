import * as tar from 'tar-stream';
import { ZipReader, BlobReader, BlobWriter } from '@zip.js/zip.js/index.cjs';
import { from as createBufferFromArrayBuffer } from 'b4a';
import * as stream from 'stream';

type ProgressCallback = (filename: string) => void;

const MAX_UNCOMPRESSED_SIZE = 2 * 1000 * 1000 * 1000;

function bytesToSize(bytes: number, decimals: number = 2): string {
  if (!Number(bytes)) {
    return '0 Bytes';
  }

  const kbToBytes = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));

  return `${parseFloat(
    (bytes / Math.pow(kbToBytes, index)).toFixed(dm),
  )} ${sizes[index]}`;
}

export async function zipToTarStream(
  inputZip: Blob,
  onProgress?: ProgressCallback,
): Promise<tar.Pack> {
  const zipReader = new ZipReader(new BlobReader(inputZip));
  const entries = await zipReader.getEntries();

  if (entries.length === 0) {
    throw new Error('No files were found in the ZIP file.');
  }

  const outputTar = tar.pack();
  let totalUncompressedSize = 0;

  for (const entry of entries) {
    if (entry.directory) continue;
    if (!entry.getData) continue;

    if (onProgress) onProgress(entry.filename);

    const fileContent = await entry.getData(new BlobWriter());
    const fileBuffer = await fileContent.arrayBuffer();
    const fileSize = fileBuffer.byteLength;

    totalUncompressedSize += fileSize;
    if (totalUncompressedSize > MAX_UNCOMPRESSED_SIZE) {
      throw new Error(
        `Uncompressed size exceeds accepted limit of ${bytesToSize(MAX_UNCOMPRESSED_SIZE)}.`,
      );
    }

    const FIXED_DATE = new Date('2025-01-01T00:00:00Z');

    outputTar.entry(
      { name: entry.filename, size: fileSize, mtime: FIXED_DATE },
      // setting a FIXED_DATE ensures that tar files from a same original zip get the same file headers and thus produce the same checksum
      // @ts-expect-error The 'entry' function is incorrectly typed, so we get an error. But the type we provide does work and is actually the correct type.
      createBufferFromArrayBuffer(fileBuffer),
    );
    if (onProgress) onProgress(entry.filename);
  }
  outputTar.finalize();

  return outputTar;
}

export async function tarStreamToBlob(tarStream: tar.Pack): Promise<Blob> {
  const arrays = new Array<Uint8Array>();
  const nodeWriter = new stream.Writable();

  nodeWriter._write = function (chunk, encoding, done) {
    arrays.push(chunk);
    done();
  };

  const pipeStream = tarStream.pipe(nodeWriter);
  await new Promise((resolve) => {
    pipeStream.on('finish', resolve);
  });

  return new Blob(arrays, { type: 'application/x-tar' });
}
